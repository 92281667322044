import React from "react"

const ArrowRightIcon = () => (
  <svg
    width={17}
    height={12}
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1L16 6L11 11"
      stroke="#1C1D1A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 6H1"
      stroke="#1C1D1A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ArrowRightIcon
