import React from "react"
import { Trans } from "react-i18next"
import { pageDirection } from "../../helpers/utils"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "./Benefits.css"

const Benefits = () => {
  const dir = pageDirection()
  const { t } = useTranslation()

  return (
    <section className="benefits-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 d-flex justify-content-center align-items-center justify-content-lg-end">
            <img
              src="https://res.cloudinary.com/trysamar/video/upload/e_loop,dl_120,fl_animated.flatten.lossy.png24,q_auto:eco/v1631109430/Marketing/Features/track_your_refunds_animated_illustration_skinless.gif"
              id="track-your-refunds"
              alt={t("animatedIllustration1Heading")}
              className="illustrated-image skinless"
            />
          </div>
          <div
            dir={dir}
            className="col-lg-4 offset-lg-2 text-center text-lg-left space-md-screen"
          >
            <div className="section-title">
              <Trans>animatedIllustration1Heading</Trans>
            </div>
            <p>
              <Trans>animatedIllustration1Body</Trans>
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-2 order-lg-2 text-center text-lg-left">
            <img
              src="https://res.cloudinary.com/trysamar/video/upload/e_loop,dl_120,fl_animated.flatten.lossy.png24,q_auto:eco/v1631109428/Marketing/Features/stay_updated_animated_illustration_skinless.gif"
              id="stay-updated"
              alt={t("animatedIllustration2Heading")}
              className="illustrated-image skinless"
            />
          </div>
          <div
            dir={dir}
            className="col-lg-4 offset-lg-2 order-lg-1 text-center text-lg-right space-md-screen"
          >
            <div className="section-title">
              <Trans>animatedIllustration2Heading</Trans>
            </div>
            <p>
              <Trans>animatedIllustration2Body</Trans>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits
