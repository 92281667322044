import React from "react"

import "./FormInput.css"
import { pageDirection } from "../../helpers/utils"
import { Trans, useTranslation } from "react-i18next"

const FormInput = ({ label, error, ...otherProps }) => {
  const dir = pageDirection()
  const { t } = useTranslation()

  return (
    <div className="group">
      <input className="form-input" {...otherProps} />
      {label ? (
        <label
          dir={dir}
          className={`${
            otherProps.value.length ? "shrink" : ""
          } form-input-label`}
        >
          {label}
        </label>
      ) : null}

      <div className="error-message">{t(error)}</div>
    </div>
  )
}

export default FormInput
