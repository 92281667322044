import React from "react"
import cn from "classnames"
import { Trans } from "gatsby-plugin-react-i18next"

import ArrowRightIcon from "../Icons/ArrowRIghtIcon"
import PlayIcon from "../Icons/PlayIcon"

import "./HeroSection.css"
import { pageDirection } from "../../helpers/utils"
import {logEvent} from "../../helpers/gtmUtil";

const HeroSection = () => {
  const [play, setPlay] = React.useState(false)
  const dir = pageDirection()
  const arrowIconClass = cn("arrow-right d-flex", {
    "mr-2": dir === "rtl",
    "ml-2": dir !== "rtl",
  })

  const onSetPlay = () => {
    setPlay(true)
    logEvent("ROUTE_CHANGED", {
      userId: 1,
    })
  }

  const onClickEarlyAccess = e => {
    const classNames = e.target.className
    logEvent(`Try Samar First Button_${classNames}`, {
      Try_Samar_First_Button: classNames,
    })
  }
  return (
    <div dir={dir} className="hero-section">
      <div className="container">
        <div className="row">
          <div className="col-md-9 mx-auto text-center p-0">
            <h1 className="font-weight-bold heading mb-0">
              <Trans>seoTagline</Trans>
            </h1>
            <br />
            {/*<p className="sub-heading">*/}
            {/*  <Trans>brandTagline</Trans>*/}
            {/*</p>*/}
          </div>
        </div>
        <div className="video-container d-flex justify-content-center align-items-center">
          {play ? (
            <div className="yt-video-container">
              <iframe
                src="https://res.cloudinary.com/trysamar/video/upload/q_auto:eco/v1631204964/Marketing/Features/samar-key-benefits-video-reel.mp4"
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <>
              <div className="phones-img"></div>
              <div
                onClick={() => onSetPlay()}
                className="play-button-container"
              >
                <PlayIcon />
              </div>
            </>
          )}
        </div>
        <a href="#get-early-access" onClick={e => onClickEarlyAccess(e)}>
          <button className="btn btn-primary text-dark cta d-flex align-items-center">
            <Trans>earlyAdoptersButton</Trans>
            <span className={arrowIconClass}>
              <ArrowRightIcon />
            </span>
          </button>
        </a>
      </div>
    </div>
  )
}

export default HeroSection
