import React, {useState} from "react"
import { Trans } from "react-i18next"
import { pageDirection } from "../../helpers/utils"
import EarlyAdoptersForm from "../EarlyAdoptersForm"

import "./ContactUs.css"

const ContactUsComponent = () => {
  const [formSubmitted = false, setFormSubmitted] = useState();

  const dir = pageDirection();
    return (
      <section className="contact-us-section mt-3" id="get-early-access">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-6 p-0">
              <div id="walletsCard"></div>
            </div>
            {
              !formSubmitted &&
              <div dir={dir} className="col-lg-5 text-central">
                <h1 className="heading">
                  <Trans>earlyAdopterHeading</Trans>
                </h1>
                <p className="subheading">
                  <Trans>earlyAdopterBody</Trans>
                </p>
                <EarlyAdoptersForm
                    onSubmitForm={()=> setFormSubmitted(true)}
                />
              </div>
            }
            {
              formSubmitted &&
              <div dir={dir} className="col-lg-5 text-central submitted">
                <h3 className="heading">
                  <Trans>earlyAdopterConfirmation</Trans>
                </h3>
                <p className="follow-up">
                  <Trans>earlyAdopterFollowUpOne</Trans>
                </p>
                <p className="follow-up">
                  <Trans>earlyAdopterFollowUpTwo</Trans>
                </p>
              </div>
            }
          </div>
        </div>
      </section>
    )
};

export default ContactUsComponent
