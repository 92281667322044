import React from "react"
import FormInput from "../FormInput"
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { getAirTableClient } from "../../helpers/utils"
import { toast } from "react-toastify"

import "./EarlyAdoptersForm.css"
import { Link } from "gatsby"

const EarlyAdoptersForm = props => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("requiredFirstNameError")),

    email: Yup.string()
      .required(t("requiredEmailError"))
      .email(t("invalidEmailError")),
    terms_and_conditions: Yup.boolean().required(
      `${t("agreeToTerms")} ${t("termsOfUseLabel")} ${t("and")} ${t(
        "dataPrivacyLabel"
      )}.`
    ),
  })
  const initialValues = { name: "", email: "", terms_and_conditions: "" }
  const handleSubmit = (values, { resetForm, setSubmitting }, ...args) => {
    const { name, email, terms_and_conditions } = values
    const data = {
      "First name": name,
      "Email address": email,
      "Terms agreed?": terms_and_conditions,
      Language: language,
      Tag: "Early Adopter",
    }
    getAirTableClient()
      .then(airtable => {
        airtable("Early Adopter Signup").create(
          data,
          { typecast: true },
          (error, record) => {
            resetForm()
            setSubmitting(false)
            if (error) {
              console.log("an error occurred", error)
              toast.error(t("toastError"))
              return
            }
            toast.success("Congrats 🎉")

            props.onSubmitForm()
            console.log("record created", record)
          }
        )
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, touched, isSubmitting }) => (
        <form className="EarlyAdoptersForm" onSubmit={handleSubmit}>
          <Field name="name">
            {({ field, form: { errors, touched } }) => (
              <FormInput
                label={<Trans>firstNameField</Trans>}
                {...field}
                error={touched[field.name] && errors[field.name]}
                htmlFor="email"
              />
            )}
          </Field>
          <Field name="email">
            {({ field, form: { errors, touched } }) => (
              <FormInput
                label={<Trans>emailField</Trans>}
                {...field}
                error={touched[field.name] && errors[field.name]}
                htmlFor="email"
              />
            )}
          </Field>
          <div className="terms_and_conditions__container">
            <label htmlFor="terms_and_conditions">
              <Field type="checkbox" name="terms_and_conditions" className="checkbox terms legal" />
              <p>
                <Trans>earlyAdopterLegal</Trans>{" "}
                <Link to="/terms-of-use" className="term-of-use legal">
                  <Trans>termsOfUseLabel</Trans>
                </Link>{" "}
                {t("and")}{" "}
                <Link to="/data-privacy" className="data-privacy legal">
                  <Trans>dataPrivacyLabel</Trans>
                </Link>
              </p>
            </label>
            {touched["terms_and_conditions"] && (
              <p className="error message">{errors["terms_and_conditions"]}</p>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary text-dark cta d-flex align-items-center"
          >
            <Trans>
              {isSubmitting ? t("submitConfirmationLoader") : t("submitButton")}
            </Trans>
          </button>
        </form>
      )}
    </Formik>
  )
}

export default EarlyAdoptersForm
