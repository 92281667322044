import * as React from "react"
import { graphql } from "gatsby"

import Benefits from "../components/Benefits"
import HeroSection from "../components/HeroSection"

// components
import Layout from "../components/layout"
import Seo from "../components/seo"
import ValuesSection from "../components/ValuesSection"
import ContactUsComponent from "../components/ContactUs"
import { useTranslation } from "gatsby-plugin-react-i18next"
const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <Layout page="home" currentpage={t("homeNav")}>
      <Seo
        title="Samar"
        defaultTitleTag="A digital wallet for tax free shopping"
      />
      <HeroSection />
      <ValuesSection />
      <Benefits />
      <ContactUsComponent />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
