import React, { useEffect, useState, useRef } from "react"
import mobilelife from "../../assets/images/mobilelife.svg"
import shield from "../../assets/icons/shield.svg"
import mountain from "../../assets/icons/mountain.svg"
import user from "../../assets/icons/user.svg"

import "./ValuesSection.css"
import { Trans } from "react-i18next"
import { pageDirection } from "../../helpers/utils"
import { useTranslation } from "react-i18next"

const benefits = [
  {
    id: 1,
    icon: shield,
    title: "benefit1Heading",
    desc: "benefit1Body",
  },
  {
    id: 2,
    icon: mountain,
    title: "benefit2Heading",
    desc: "benefit2Body",
  },
  {
    id: 3,
    icon: user,
    title: "benefit3Heading",
    desc: "benefit3Body",
  },
]

const ValuesSection = () => {
  const dir = pageDirection()
  const { t } = useTranslation()
  const valuesCardref = useRef()
  const [valueofLanguage, setValueofLangauge] = useState(null)
  const getArabicStyleRef = () => {
    const rtl = valuesCardref.current.dir
    if (rtl === "rtl") {
      setValueofLangauge(true)
    } else {
      setValueofLangauge(false)
    }
    return valueofLanguage
  }

  useEffect(() => {
    getArabicStyleRef()
  }, [valueofLanguage])
  return (
    <section className="traveller-section-height">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center row">
            <img
              src={mobilelife}
              alt="For the travelling shopper and international citizen."
            />
          </div>
          <div
            dir={dir}
            className="col-md-7 mx-auto text-center traveller-section-title"
            ref={valuesCardref}
          >
            <h1 className="heading">
              <Trans>brandCaption</Trans>
            </h1>
            <p className="subheading">
              <Trans>brandNarrative</Trans>
            </p>
            <p>
              <Trans>availableCountry</Trans>
            </p>
          </div>
        </div>
      </div>

      <div dir={dir} className="container-lg">
        <div className="traveller-row">
          {benefits.map(({ title, desc, icon, id }) => (
            <article
              className={`card-text-container mr-item-${valueofLanguage}`}
              key={id}
            >
              <img src={icon} className={`icon-mobile-size card-text-holder-${valueofLanguage}`} alt={t(title)} />
              <div>
                <h1 className="d-flex">
                  <Trans>{t(title)}</Trans>
                </h1>
                <p>
                  <Trans>{desc}</Trans>
                </p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ValuesSection
